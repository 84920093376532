<template>
  <div>
    <v-container
      v-if="! customHomeWithParams"
      class="d-flex flex-column justify-center  pa-6"
    >
      <div class="text-center d-flex flex-column align-center my-2">
        <h1 class="text-h5 text-sm-h4">{{ $t('welcome_to') }}</h1>
        <v-img
          src="@/assets/logo_jamworks.svg"
          :width="$vuetify.breakpoint.xs ? '320px' : '370px'"
          :height="$vuetify.breakpoint.xs ? '40px' : '45px'"
          contain
        ></v-img>
        <h2 class="text-subtitle-2 text-sm-h6 text--secondary">
          {{ $t('disclaimer') }}
        </h2>
      </div>

      <div class="pa-6">
        <v-img
          :max-height="$vuetify.breakpoint.lgAndDown ? '370px' : '550px'"
          src="@/assets/home_img.svg"
          contain
        ></v-img>
      </div>

      <div
        class="d-flex justify-center text-center text-body-1 primary--text my-2"
      >
        <v-icon color="primary"> mdi-arrow-left </v-icon>
        <p class="font-weight-medium text-decoration-underline mb-0 pl-1">
          {{ $t('to_get_started') }}
        </p>
      </div>
    </v-container>

    <v-card
      v-else
      class="iframe-container"
      flat
      tile
    >
      <iframe :src="customHomeWithParams.access_url" frameborder="0"></iframe>
    </v-card>
  </div>
</template>

<style scoped>
  .container {
    height: calc(100vh - 64px);
  }

  .iframe-container {
    height: calc(100vh - 64px);
  }

  iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>

<script>

import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: "Home",

  data() {
    return {
      customHome: null,
    }
  },

  watch: {
    'appId': function() {
      this.fetchCustomHome(this.appId)
    }
  },

  mounted() {
    this.fetchCustomHome(this.appId)
  },

  computed: {
    ...mapState({
      userApplications: state => state.app.userApplications,
      userToken: state => state.core.auth.token,
    }),

    appId() {
      return this.$route.query.appId
    },

    customHomeWithParams() {
      if (! this.customHome) {
        return null
      }

      const updatedAccessUrl = new URL(this.customHome.access_url)

      updatedAccessUrl.searchParams
        .append('token', this.userToken)

      updatedAccessUrl.searchParams
        .append('appId', this.customHome.application_instance_id)

      return {
        ...this.customHome,
        access_url: updatedAccessUrl
      }
    },
  },

  methods: {
    fetchCustomHome(selectedCustomHome) {
      if (! selectedCustomHome) {
        selectedCustomHome = localStorage.getItem('selectedHome') || null

        if (selectedCustomHome) {
          router.push({
            name: 'Home',
            query: { appId: selectedCustomHome }
          })

          return
        }
      }

      if (selectedCustomHome) {
        const foundCustomHome = this.userApplications.find(app => app.application_instance_id == selectedCustomHome)

        if (foundCustomHome) {
          localStorage.setItem('selectedHome', selectedCustomHome)

          this.customHome = foundCustomHome

          return
        }
      }

      this.customHome = this.userApplications.find(app => app.application_key === 'home')

      if (this.customHome) {
        localStorage.setItem('selectedHome', this.customHome.application_instance_id)
      }
    },
  }
}

</script>